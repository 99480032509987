import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Avatar, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import Constant from "../../assets/js/Constant";
import { Logout } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Message from "./Message";
import Label from "./Label";

/**
 * @name MenuCard
 * @description Método encargado de devolver un componente tipo MenuCard (Avatar más la cuenta de usuario)
 * @param {useState} component
 * @param {useState} setComponent
 * @param {Boolean} logoutItem
 * @returns View
 * @version 1.0
 */
const MenuCard = ({ component, setComponent, logoutItem }) => {
  const constant = Constant();
  const open = Boolean(component);
  const { lang, user, signout, backItem, backForm, setForm } = useThis();
  const css = styles(logoutItem, backItem);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [letter, setLetter] = useState("");
  const [lastSignIn, setLastSignIn] = useState("");

  /**
   * @name onClickBackView
   * @description Método para regresar a la pantalla anterior
   */
  const onClickBackView = () => setForm(backForm);

  /**
   * @name onClickLogout
   * @description Método encargado de cerrar sesión
   */
  const onClickLogout = () => signout().catch(() => setSnack([9, constant.error]));
  /**
   * @name handleClose
   * @description Método encargado de cerrar el menú
   */
  const handleClose = () => setComponent(null);

  /**
   * @name formatDate
   * @description Método encargado de formatear un timestamp
   * @param {Date} timestamp
   * @returns String - Fecha formateada
   */
  const formatDate = (timestamp) => {
    const date = new Date(+timestamp);
    const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" };
    return date.toLocaleString(lang.language, options);
  };

  useEffect(() => {
    // Establece la letra en el avatar y el color de estado
    if (!user) return;
    if (user.email) {
      setEmail(user.email);
      setLetter(user.email.substring(0, 1));
    }
    if (user.displayName) {
      setName(user.displayName);
      setLetter(user.displayName.substring(0, 1));
    }

    // Toma la última fecha de ingreso del token
    const timestamp = user.metadata.lastLoginAt;
    setLastSignIn(formatDate(timestamp));
  }, [user]);

  return (
    <Menu
      open={open}
      anchorEl={component}
      onClose={handleClose}
      onClick={handleClose}
      anchorOrigin={css.anchor}
      transformOrigin={css.trans}
      PaperProps={{ elevation: 0, sx: css.menu }}
    >
      <Label text={name} fontWeight={600} textAlign="center" margin="10px 0 0 0" color="white" />
      <Label text={email} textAlign="center" color="#adf1c4" />
      <div style={css.containerAvatar}>
        <Avatar sx={css.avatar}>{letter}</Avatar>
      </div>
      <div style={css.containerItems}>
        <Label text={lang.lastLogin} fontWeight={600} textAlign="center" color="white" fontSize="14px" />
        <Label text={lastSignIn} textAlign="center" margin="5px 20px 40px 20px" fontSize="12px" color="white" />
        <MenuItem
          sx={css.back}
          onClick={onClickBackView}
          onMouseLeave={(e) => (e.target.style.background = "none")}
          onMouseEnter={(e) => (e.target.style.background = "#1C1C1C")}
        >
          <ListItemIcon>
            <KeyboardBackspaceIcon sx={css.icon} fontSize="small" />
          </ListItemIcon>
          {lang.exit}
        </MenuItem>
        <MenuItem
          sx={css.logout}
          onClick={onClickLogout}
          onMouseLeave={(e) => (e.target.style.background = "none")}
          onMouseEnter={(e) => (e.target.style.background = "#1C1C1C")}
        >
          <ListItemIcon>
            <Logout sx={css.icon} fontSize="small" />
          </ListItemIcon>
          {lang.signOff}
        </MenuItem>
      </div>
      <Message snack={snack}></Message>
    </Menu>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Boolean} logoutItem
 * @param {Boolean} backItem
 * @returns Object
 */
const styles = (logoutItem, backItem) => {
  return {
    icon: { color: "white" },
    trans: { horizontal: "right", vertical: "top" },
    anchor: { horizontal: "right", vertical: "bottom" },
    back: { display: backItem ? "flex" : "none", color: "white !important" },
    logout: { display: logoutItem ? "flex" : "none", color: "white !important" },
    containerAvatar: { height: "40px", position: "relative", display: "flex", justifyContent: "center" },
    avatar: {
      bottom: "-20px",
      color: "#444444",
      position: "absolute",
      background: "#adf1c4",
      margin: "0 !important",
      width: "40px !important",
      height: "40px !important",
    },
    menu: {
      mt: 1.5,
      color: "white",
      bgcolor: "black",
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      "& .MuiAvatar-root": {
        mr: 1,
        ml: -0.5,
        width: 32,
        height: 32,
      },
      "&::before": {
        top: 0,
        zIndex: 0,
        right: 14,
        width: 10,
        height: 10,
        content: '""',
        display: "block",
        bgcolor: "black",
        position: "absolute",
        transform: "translateY(-50%) rotate(45deg)",
      },
    },
    containerItems: {
      height: "auto",
      paddingTop: "30px",
      paddingBottom: "15px",
      background:
        "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(8,8,8,1) 50%, rgba(13,13,13,1) 75%, rgba(18,18,18,1) 100%, rgba(61,61,61,1) 100%)",
    },
  };
};

export default MenuCard;
