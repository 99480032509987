import getConstant from "../js/Constant";

/**
 * @name messages_es
 * @description Método que devuelve los mensajes en español para el componente SnackBar
 * @returns {Object} Objeto con los mensajes en español
 */
const messages_es = () => {
  const constant = getConstant();

  return {
    lang: "lang-es",
    1: "Ingrese su correo electrónico",
    2: "Ingrese su contraseña",
    3: "Entra al enlace enviado a tu correo",
    4: "Debe ingresar un nombre de usuario",
    5: `La contraseña debe tener ${constant.passwordLength} o más caracteres.`,
    6: "El correo electrónico es incorrecto",
    7: "La contraseña es incorrecta",
    8: "El correo electrónico ya existe",
    9: "Ha ocurrido un error no controlado",
    10: "Ha ocurrido un error al enviar el correo",
    11: "Complete el proceso de registro",
    12: "Proceso de registro completado",
    13: "Vuelva a intentar en un momento",
    14: "Este correo aún no se ha registrado",
    15: "Verifique sus datos",
    16: "Verifique su conexión a internet",
    17: "El campo Identificación es requerido",
    18: "El campo Nombre es requerido",
    19: "Error al consultar la identificación",
    20: "No se encuentra inscrito(a) en Hacienda",
    21: "Error en el servidor de Hacienda!",
    22: "Falta código de actividad de Hacienda",
    23: "Error al crear la sucursal",
    24: "Usuario inválido",
    25: "Sucursal agregada con éxito",
    26: "Error al obtener la configuración",
    27: "No cuenta con los permisos de administrador",
    28: "Request inválido",
    29: "Ocurrió un error al realizar el registro",
    30: "Código de sucursal incorrecto",
    31: "Digite el código de la sucursal",
    32: "No se pudo eliminar la sucursal",
    33: "Sucursal eliminada con éxito",
    34: "Ocurrió un error al eliminar la sucursal",
    35: "Error al consultar las suscripciones",
    36: "Error al cargar!",
    37: "Error al crear la suscripción",
    38: "Error al obtener datos de la sucursal",
    39: "Se creó la solicitud de suscripción",
    40: "Suscripciones pendientes de aprobar",
    41: "Este método de pago está inactivo",
    42: "Error en almacenar el archivo",
    43: "Es necesario adjuntar el comprobante",
    44: "Error al agregar claims",
  };
};

export default messages_es;
