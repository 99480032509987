/**
 * @name lang_es
 * @description Método que devuelve los textos en español
 * @returns {Object} Objeto con los textos en español
 */
const lang_es = () => {
  return {
    language: "es-ES",
    or: "Ó",
    back: "Atrás",
    next: "Siguiente",
    lang: "lang-es",
    footer: "© 2024 Broshetta",
    email: "Correo electrónico",
    continue: "Continuar",
    login: "Iniciar sesión",
    password: "Contraseña",
    btnGoogle: "Continuar con Google",
    btnMicrosoft: "Continuar con Microsoft",
    remember: "Recordarme",
    forgotText: "¿Olvidó la contraseña?",
    haveAccount: "¿No tienes una cuenta?",
    register: "Regístrate ahora",
    requestPassword: "Solicitar cambio de contraseña",
    changePassword: "Cambiar contraseña",
    user: "Usuario",
    credentials: "Credenciales",
    verification: "Verificación",
    userName: "Nombre de usuario",
    registerTitle: "Registro",
    createUser: "Creación de usuario",
    createUserText: "El siguiente nombre de usuario será utilizado en la administración de todo el sistema y se mostrará en los comprobantes.",
    condition: "*Al registrarse está aceptando nuestro contrato de términos y condiciones.",
    registerCredentials: "Registro de credenciales",
    emailAndPassword: "Ingrese su correo electrónico y contraseña.",
    verify: "Verificación del correo electrónico",
    registerSuccess: "El registro fue exitoso!",
    verifyText1: "Ahora verifique el enlace que se envió al correo ",
    verifyText2: " para poder iniciar la sesión.",
    wait: "Enviando correo...",
    sent: "El correo se envió correctamente!",
    errorSent: "Error al enviar correo!",
    resend: "Reenviar correo",
    verified: "El correo ha sido verificado",
    signOff: "Cerrar sesión",
    branch: "Sucursal",
    lastLogin: "Último ingreso:",
    exit: "Salir",
    administrator: "Administrador",
    collaborator: "Colaborador",
    administratorText: "Al ingresar al sistema como Administrador, prodrá gestionar su propio restaurante, como también agregar colaboradores al mismo.",
    collaboratorText: "Para poder ingresar al sistema como Colaborador, debe contar con los permisos otorgados por un administrador.",
    selectEntryText: "Seleccione el tipo de entrada al sistema",
    createBranch: "Crear sucursal",
    hi: "HOLA",
    initMessage1: "Primero crea una sucursal...",
    initMessage2: "Aún no estás vinculado...",
    rotate: "Por favor, gire su dispositivo.",
    close: "Cerrar ventana",
    add: "Agregar",
    identification: "*Identificación",
    country: "País",
    other: "Otro",
    cr: "Costa Rica",
    electronicBill: "Facturación electrónica:",
    yes: "Sí",
    no: "No",
    generalData: "Datos generales:",
    disabledCountry: "Deshabilitado para este país",
    branchName: "*Nombre",
    phone: "Teléfono",
    uploadImage: "Cargar imagen",
    requiredFields: "*Campos requeridos",
    selectLogo: "Seleccione el logo:",
    typeID: "Tipo de identificación",
    tradename: "Nombre comercial",
    selectAddress: "Seleccione la dirección:",
    selectProvince: "Seleccione una provincia",
    selectCanton: "Seleccione un cantón",
    selectDistrict: "Seleccione un distrito",
    physical: "Física nacionales",
    legal: "Jurídica nacionales",
    dimex: "Dimex",
    nite: "Nite",
    activityCode: "Código de actividad",
    otherAddress: "Otras señas",
    idePlusEnter: "*Identificación + tecla enter",
    branchNameForEB: "*Nombre / Razón social",
    fullScreen: "Pantalla completa",
    go: "Ir",
    options: "Opciones",
    created: "Creada",
    expiration: "Vencimiento",
    address: "Dirección",
    noAddress: "Sin dirección!",
    manageUsers: "Administrar usuarios",
    subscriptions: "Suscripciones",
    editBranch: "Editar sucursal",
    deleteBranch: "Eliminar sucursal",
    delete: "Eliminar",
    codeBranchMessage: "Digite el código de la sucursal [CODE], un caracter por cada campo.",
    deleteBranchMessage: "Para eliminar esta sucursal debe ingresar el código de la misma: ",
    deleteBranchWarning: "Advertencia! Esta acción es irreversible, y se eliminarán todos los datos vinculados a esta sucursal.",
    totalOrders: "Total de órdenes: ",
    totalAmount: "Total de pagos: ",
    remainingSubscription: "Suscripción: ",
    expandSubscription: "Ampliar subscripción",
    subscriptionTimeRemainingAt0: "0 días y 0 horas",
    daysAnd: " días y ",
    dayAnd: " día y ",
    hours: " horas",
    hour: " hora",
    subscription: "Suscripción",
    initialPlan: "Inicio del plan",
    planExpiration: "Expiración del plan",
    remainingDays: "Días restantes",
    status: "Estado",
    ammount: "Monto",
    paymentMethod: "Método de pago",
    plan: "Plan",
    term: "Plazo",
    subscriptionDetails: "Detalle de la suscripción",
    day: "día",
    days: "días",
    month: "mes",
    months: "meses",
    year: "año",
    years: "años",
    renewYourPlan: "Renueva tu plan",
    planType: "Tipo",
    planTerm: "Plazo",
    summary: "Resumen",
    methodPay: "Pago",
    docType1:
      "El plan de Comprobantes electrónicos, es fundamental para todos aquellos restaurantes a los que el país les ha establecido requisitos legales y fiscales, como lo es la emisión de facturas electrónicas y/o tiquetes electrónicos.",
    docType2:
      "Este plan es ideal para todos aquellos restaurantes en los que NO es obligatorio la emisión de documentos electrónicos, como lo son facturas electrónicas y/o tiquetes electrónicos. Generando solamente un recibo de caja.",
    physicalDocuments: "Comprobantes físicos",
    electronicDocuments: "Comprobantes electrónicos",
    selectPlan: "Seleccione el tipo de plan:",
    description: "Descripción",
    messageTerm: "El plazo inicia desde la fecha de expiración del último plan registrado, hasta los [DAYS] días después de este plan.",
    price: "Precio (I.V.I):",
    termPeriod: "Periodo del plazo: ",
    selectTerm: "Seleccione el plazo:",
    typePlanSelected: "(Tipo de plan seleccionado)",
    planSelected: "(Plan seleccionado)",
    note: "Nota:",
    renewSummary:
      "Recuerde que el plan inicia desde la fecha de expiración del último plan registrado. Por otro lado, al continuar (con la flecha siguiente), está aceptando los datos presentados en este apartado.",
    sinpe: "Sinpe móvil",
    transfer: "Transferencia",
    card: "Tarjeta",
    attachDoc: "Comprobante",
    generateSub: "Generar suscripción",
    sinpeText: "Es necesario adjuntar el comprobante de pago.",
    confirmDoc: "Una vez confirmemos la transacción, se aprobará esta suscripción.",
    sinpeNote: "Nota: Al presionar el botón GENERAR SUSCRIPCIÓN, recibiremos una notificación con los datos de su solicitud.",
    selectPaymentMethod: "Seleccione el método de pago:",
    alert: "Aviso",
    paymentMethods: {
      SM: "Sinpe Móvil",
      TR: "Transferencia",
      CA: "Tarjeta",
      PP: "Paypal",
    },
    currencies: {
      CRC: "Colones",
      USD: "Dólares",
    },
    notAvailable: "Aún no se encuentra disponible!",
    approved: "Activo",
    pending: "Pendiente",
    expired: "Expirado"
  };
};

export default lang_es;
