/**
 * @name endpoint
 * @description Objeto que contiene todos los endpoints de la base de datos, necesarios para las consultas.
 * @returns {String} endpoint
 * @version 1.0
 */
const endpoints = {
  branches: (userID) => `/accounts/${userID}/branch`,
  branch: (userID, branchID) => `/accounts/${userID}/branch/${branchID}`,
  subscriptions: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/subscriptions`,
  storageFile: (userID, branchID, type, fileName) => `/${userID}/${branchID}/images/subscriptions/${type}/${fileName}`,
};

export default endpoints;
