import SelectEntry from "./views/components/SelectEntry";
import Background from "./views/containers/Background";
import { useThis } from "./assets/context/Context";
import Login from "./views/components/Login";
import { useEffect } from "react";
import "../src/css/global.css";

/**
 * @name App
 * @description Inicia la parte gráfica del sistema. Se comprueba que el usuario exista, si no,
 * se envía hacer login o signup. Se compruba que el usuario haya verificado el correo al registrarse
 * @returns view
 */
const App = () => {
  const { lang, form, setForm, user } = useThis();

  useEffect(() => {
    if (!user) return setForm(<Login />);
    if (user && user.emailVerified) setForm(<SelectEntry />);
  }, [user]);

  return <Background component={form} text={lang.footer} />;
};

export default App;
