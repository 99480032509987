import { useThis } from "../../assets/context/Context";
import "../../css/initMessage.css";

/**
 * @name InitMessage
 * @description Método encargado de mostrar el texto animado
 * @returns View
 * @version 1.0
 */
const InitMessage = () => {
  const { lang, entryType } = useThis();
  return (
    <div className="initMessage">
      <h3>{lang.hi}</h3>
      <p className="initMessage-animation">{entryType === 0 ? lang.initMessage1 : lang.initMessage2}</p>
    </div>
  );
};

export default InitMessage;
