import GenericStepHorizontal from "./GenericStepHorizontal";
import GenericStepVertical from "./GenericStepVertical";
import { useThis } from "../../assets/context/Context";
import { useEffect, useState } from "react";
import RenewStep1 from "./RenewStep1";
import RenewStep2 from "./RenewStep2";
import RenewStep3 from "./RenewStep3";
import RenewStep4 from "./RenewStep4";
import Message from "./Message";

/**
 * @name RenewPlan
 * @description Método que carga el contenido del modal para renovar suscripciones
 * @param {Object} { consecutiveBranch }
 * @returns View
 * @version 1.0
 */
const RenewPlan = ({ consecutiveBranch }) => {
  const { isMobile, lang } = useThis();
  const css = styles(isMobile);

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [steper, setSteper] = useState(<></>); // Variable de estado con el tipo de steper

  /** Efecto para cargar los pasos */
  useEffect(() => {
    const steps = [lang.planType, lang.planTerm, lang.summary, lang.methodPay];
    const views = [<RenewStep1 />, <RenewStep2 />, <RenewStep3 />, <RenewStep4 consecutiveBranch={consecutiveBranch} setSnack={setSnack} />];

    if (isMobile) {
      setSteper(<GenericStepVertical steps={steps} views={views} />);
    } else {
      setSteper(<GenericStepHorizontal steps={steps} views={views} />);
    }
  }, []);

  return (
    <>
      <div style={css.container}>{steper}</div>
      <Message snack={snack}></Message>
    </>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Boolean} isMobile
 * @returns Object
 * @version 1.0
 */
const styles = (isMobile) => {
  return {
    container: { display: "flex", justifyContent: "center", alignItems: "center", height: isMobile ? "auto" : "100%" },
  };
};

export default RenewPlan;
