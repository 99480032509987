import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Message from "./Message";
import Label from "./Label";
import Login from "./Login";
import Logo from "./Logo";

/**
 * @name StepRegister3
 * @description Método que devuelve un componente de tipo StepRegister3
 * @returns View
 * @version 1.0
 */
const StepRegister3 = () => {
  const { lang, data, user, sendEmailVerification, setStep, setForm } = useThis();
  const constant = getConstant();

  const [verified, setVerified] = useState(false);
  const [sending, setSending] = useState(true);
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const css = styles(sent, verified);

  /**
   * @name onClickToBack
   * @description Método que se devuelve a la pantalla de login
   * @returns N/A
   */
  const onClickToBack = () => setForm(<Login />);

  /**
   * @name onClickToReload
   * @description Método que recarga la página para inciar el login
   * @returns N/A
   */
  const onClickToReload = () => {
    if (sent) window.location.reload();
  };

  /**
   * @name onClickToSend
   * @description Método que llama el método de enviar validación
   * @returns N/A
   */
  const onClickToSend = () => {
    if (sending) return;
    sendEmail();
  };

  /**
   * @name sendEmail
   * @description Método encargado de inviar un correo para su validación
   */
  const sendEmail = () => {
    setSending(true);
    sendEmailVerification(user)
      .then(() => {
        setSent(true);
        setError(false);
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests") {
          setSnack([13, constant.error]);
        } else {
          setSnack([10, constant.error]);
        }
        setError(true);
      })
      .finally(() => setSending(false));
  };

  useEffect(() => {
    // Muestra el paso 3 de registro completado
    if (user && user.emailVerified) {
      setStep(3);
      setVerified(true);
      setSending(false);
      return setSnack([12, constant.success]);
    }
    sendEmail();
  }, []);

  return (
    <div style={css.container}>
      <div style={css.fragment}>
        <Logo type={5} size={200} />
        <div style={css.right}>
          <Label text={lang.verify} fontSize="20px" fontWeight="500" />
          <div style={css.success}>
            <CheckIcon sx={css.checkIcon} />
            <Label text={lang.registerSuccess} fontSize="16px" margin="0 0 0 10px" fontWeight="700" />
          </div>
          <div style={css.wait}>
            {sending ? (
              <CircularProgress style={css.circle} color={constant.warning} />
            ) : error ? (
              <CloseIcon sx={css.closeIcon} />
            ) : (
              <CheckIcon sx={css.checkIcon} />
            )}
            <Label text={sending ? lang.wait : error ? lang.errorSent : lang.sent} fontSize="16px" margin="0 0 0 10px" fontWeight="700" />
          </div>
          <div style={css.verified}>
            <CheckIcon sx={css.checkIcon} />
            <Label text={lang.verified} fontSize="16px" margin="0 0 0 10px" fontWeight="700" />
          </div>
          <p style={css.verifyText}>
            {lang.verifyText1}
            <span style={css.email}>{data.email}</span>
            {lang.verifyText2}
          </p>
        </div>
      </div>
      <div style={css.footer}>
        <p className="clickText" style={css.signUpText} onClick={verified ? onClickToBack : error ? onClickToSend : onClickToReload}>
          {verified ? lang.back : error ? lang.resend : lang.login}
        </p>
      </div>
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = (sent, verified) => {
  return {
    email: { fontWeight: "600" },
    right: { margin: "0", width: "380px" },
    circle: { width: "24px", height: "24px" },
    wait: { display: "flex", alignItems: "center" },
    checkIcon: { color: "#43995E", fontSize: "24px" },
    closeIcon: { color: "#AF1E1E", fontSize: "24px" },
    footer: { display: "flex", justifyContent: "end", marginTop: "0px" },
    success: { display: "flex", alignItems: "center", marginTop: "10px" },
    verified: { display: verified ? "flex" : "none", alignItems: "center" },
    verifyText: { fontSize: "16px", display: sent ? "block" : "none", marginTop: "10px" },
    signUpText: { fontSize: "14px", fontWeight: "500", cursor: "pointer", color: "#4b4b4b" },
    fragment: { display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "10px", height: "275px" },
    container: { width: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between" },
  };
};

export default StepRegister3;
