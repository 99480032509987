/**
 * @name getConstant
 * @description Método encargado de devolver un objeto con las constantes de la aplicación
 * @returns Object
 */
const getConstant = () => {
  return {
    info: "info",
    error: "error",
    passwordLength: 6,
    warning: "warning",
    success: "success",
    maxScreenWidth: 768,
    infoColor: "#177FA0",
    approved: "approved",
    checkColor: "#5CB67F",
    deleteColor: "#8A1521",
    primaryColor: "#5CB67F",
    unsubscribed: "Desinscrito",
    notRegistered: "No inscrito",
    //functionsBasePath: "/api/v1",
    onlyImage: "image/png,image/jpeg",
    branchCode: { cod1: "", cod2: "", cod3: "" },
    _PROVINCE: { firstLetter: "A", id: 2, name: "Alajuela" },
    _CANTON: { firstLetter: "A", id: 201, name: "Alajuela" },
    _DISTRICT: { firstLetter: "A", id: 20101, name: "Alajuela" },
    data: { email: "", password: "", userName: "", remember: false },
    getInfoByIdePath: "https://api.hacienda.go.cr/fe/ae?identificacion=",
    activity: { id: 0, name: "Sin actividades registradas", icon: null, code: "0" },
    functionsBasePath: "http://127.0.0.1:5001/broshetta-web/us-central1/functions/api/v1",
  };
};

export default getConstant;
