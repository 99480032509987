import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { getBranches } from "../../assets/js/firebaseMethods";
import { CircularProgress, Typography } from "@mui/material";
import SpeedDialButton from "../components/SpeedDialButton";
import { useThis } from "../../assets/context/Context";
import SelectEntry from "../components/SelectEntry";
import getConstant from "../../assets/js/Constant";
import { useEffect, useState } from "react";
import v1 from "../../images/v1.svg";
import v2 from "../../images/v2.svg";
import v3 from "../../images/v3.svg";
import v4 from "../../images/v4.svg";
import v5 from "../../images/v5.svg";
import v6 from "../../images/v6.svg";
import "../../css/lobby.css";

/**
 * @name Lobby
 * @description Método encargado de devolver un componente de tipo Lobby
 * @returns View
 * @version 1.0
 */
const Lobby = () => {
  const constant = getConstant();
  const css = styles(constant.primaryColor);
  const { entryType, setBackItem, setBackForm, lang, setYearText, user, isMobile, setSnapshotBranches } = useThis();

  const [view, setView] = useState(<CircularProgress sx={css.progress} />);
  const [speedDialButton, setSpeedDialButton] = useState(null);

  useEffect(() => {
    // Establece el botón de atrás en el menú del avatar y la pantalla anterior
    setBackItem(true);
    setBackForm(<SelectEntry />);
    // Establece el view principal, si no hay sucursales creadas se muestra el texto animado
    getBranches(setView, setYearText, user.uid, isMobile, setSnapshotBranches);
    // Valida si inició como administrador para poder crear sucursales
    if (entryType === 0) setSpeedDialButton(<SpeedDialButton />);
  }, []);

  return (
    <div id="sky" style={css.sky}>
      <Title lang={lang} css={css} entryType={entryType} />
      <div className="cloud x1"></div>
      <div className="cloud x2"></div>
      <div className="cloud x3"></div>
      <div className="cloud x4"></div>
      <div className="cloud x5"></div>
      <div className="cloud x6"></div>
      <img src={v1} className="vegetables v1" alt="V1" />
      <img src={v2} className="vegetables v2" alt="V2" />
      <img src={v3} className="vegetables v3" alt="V3" />
      <img src={v4} className="vegetables v4" alt="V4" />
      <img src={v5} className="vegetables v5" alt="V5" />
      <img src={v6} className="vegetables v6" alt="V6" />
      <img src={v1} className="vegetables v7" alt="V7" />
      <img src={v2} className="vegetables v8" alt="V8" />
      <img src={v3} className="vegetables v9" alt="V9" />
      <img src={v4} className="vegetables v10" alt="V10" />
      <img src={v5} className="vegetables v11" alt="V11" />
      <img src={v6} className="vegetables v12" alt="V12" />
      <div style={css.container}>{view}</div>
      {speedDialButton}
    </div>
  );
};

/**
 * @name Title
 * @description Método que devuelve un componente de tipo Title
 * @param {Object} lang
 * @param {Object} css
 * @param {Number} entryType
 * @returns View
 */
const Title = ({ lang, css, entryType }) => {
  const types = [lang.administrator, lang.collaborator];
  const icons = [<AdminPanelSettingsIcon sx={css.icon} />, <FolderSharedIcon sx={css.icon} />];
  return (
    <Typography variant="h7">
      <div style={css.title}>
        {icons[entryType]}
        {types[entryType]}
      </div>
    </Typography>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} primaryColor
 * @returns Object
 */
const styles = (primaryColor) => {
  return {
    sky: { overflow: "hidden" },
    progress: { color: primaryColor },
    icon: { color: primaryColor, marginRight: "5px" },
    container: { width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" },
    title: { display: "flex", alignItems: "center", color: "#333333", position: "absolute", top: "10px", left: "10px" },
  };
};

export default Lobby;
