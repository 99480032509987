import { SpeedDial, SpeedDialAction, SpeedDialIcon, styled } from "@mui/material";
import { createBranch } from "../../assets/js/firebaseMethods";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { Close, AddBusiness } from "@mui/icons-material";
import { useThis } from "../../assets/context/Context";
import { darkenColor } from "../../assets/js/Commons";
import getConstant from "../../assets/js/Constant";
import CreateBranch from "./CreateBranch";
import { useRef, useState } from "react";
import Modal from "../containers/Modal";
import cr from "../../images/cr.svg";
import Message from "./Message";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name SpeedDialButton
 * @description Método encargado de devolver un componente de tipo SpeedDialButton
 * @returns View
 * @version 1.0
 */
const SpeedDialButton = () => {
  const css = styles();
  const constant = getConstant();
  const { user, lang, dataBranch, setDataBranch, entryType } = useThis();

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const ideFocus = useRef(null);
  const nameFocus = useRef(null);
  const emailFocus = useRef(null);

  /**
   * @name addBranches
   * @description Método encargado de mostrar el modal para crear las sucursales
   */
  const addBranches = () => setOpen(true);

  /**
   * @name onClickBtnOk
   * @description Método que maneja el click del botón del Modal, para almacenar la nueva sucursal
   * @version 1.0
   */
  const onClickBtnOk = async () => {
    // Valida si inició como administrador para poder crear sucursales
    if (entryType !== 0) return setSnack([27, constant.error]);
    // Valida que no se haya presionado el botón de crear sucursal
    if (loading) return;

    let data = { ...dataBranch };

    if (!data.identification) {
      ideFocus.current.focus();
      setSnack([17, constant.error]);
      return;
    }
    if (!data.name) {
      nameFocus.current.focus();
      setSnack([18, constant.error]);
      return;
    }
    if (data.electronicBill && data.activityCode.code === "0") {
      setSnack([22, constant.error]);
      return;
    }
    if (data.electronicBill && !data.email) {
      emailFocus.current.focus();
      setSnack([1, constant.error]);
      return;
    }
    if (data.electronicBill && !data.otherAddress) {
      data = { ...data, otherAddress: `${data.province.name}, ${data.canton.name}, ${data.district.name}.` };
    }
    if (!data.electronicBill) data = { ...data, ...defaultDataBranch() };

    setLoading(true);
    deleteDataBranch(data);
    const response = await createBranch(user, data);
    validateResponse(response);
  };

  /**
   * @name validateResponse
   * @description Valida la respuesta al crear una sucursal
   * @param {Object} response
   */
  const validateResponse = (response) => {
    if (response.hasOwnProperty("error") && response.error) {
      setSnack([response.errorCode, constant.error]);
    } else {
      setOpen(false);
      setSnack([25, constant.success]);
      setDataBranch(cleanDataBranch());
    }
    setLoading(false);
  };

  /**
   * @name deleteDataBranch
   * @description Método encargado de eliminar los datos innecesarios para el request de crear sucursal
   * @param {Object} data request
   */
  const deleteDataBranch = (data) => {
    delete data.typeID.code;
    delete data.typeID.icon;
    delete data.typeID.name;
    delete data.country.icon;
    delete data.country.name;
    delete data.activityCode.icon;
    delete data.activityCode.name;
  };

  /**
   * @name defaultDataBranch
   * @description Método que devuelve un objeto con datos de la sucursal por defecto, cuando no es con factura electrónica
   * @returns Object
   */
  const defaultDataBranch = () => {
    return {
      email: "",
      tradename: "",
      otherAddress: "",
      canton: constant._CANTON,
      province: constant._PROVINCE,
      district: constant._DISTRICT,
      activityCode: constant.activity,
      typeID: { id: 0, name: lang.physical, code: "CR", type: "01" },
    };
  };

  /**
   * @name cleanDataBranch
   * @description Método que limpia los datos de la sucursal.
   * @returns Object
   */
  const cleanDataBranch = () => {
    return {
      logo: "",
      name: "",
      phone: "",
      email: "",
      tradename: "",
      otherAddress: "",
      identification: "",
      electronicBill: false,
      canton: constant._CANTON,
      province: constant._PROVINCE,
      district: constant._DISTRICT,
      activityCode: constant.activity,
      country: { id: 0, name: lang.cr, icon: cr, code: "CR", area: 506 },
      typeID: { id: 0, name: lang.physical, icon: cr, code: "CR", type: "01" },
    };
  };

  /**
   * @description Objeto para la creación del modal Crear Sucursal
   */
  const parameters = {
    btnText: lang.add,
    title: lang.createBranch,
    icon: <AddBusinessIcon />,
    content: <CreateBranch ideFocus={ideFocus} nameFocus={nameFocus} emailFocus={emailFocus} setLoading={setLoading} />,
  };

  return (
    <div>
      <CustomizedSpeedDial ariaLabel="" sx={css.speedDial} icon={<SpeedDialIcon openIcon={<Close />} />}>
        <SpeedDialAction icon={<AddBusiness />} tooltipTitle={lang.createBranch} onClick={addBranches} />
      </CustomizedSpeedDial>
      <Modal
        open={open}
        loading={loading}
        setOpen={setOpen}
        icon={parameters.icon}
        title={parameters.title}
        clickBtnOk={onClickBtnOk}
        btnText={parameters.btnText}
        content={parameters.content}
      />
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name CustomizedSpeedDial
 * @description Agrega estilo al SpeedDial
 */
const CustomizedSpeedDial = styled(SpeedDial)`
  .MuiSpeedDial-fab {
    background: ${constant.primaryColor};
  }
  .MuiSpeedDial-fab:hover {
    background: ${darkenColor(constant.primaryColor, 10)};
  }
`;

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    speedDial: { position: "absolute", bottom: 20, right: 25 },
  };
};

export default SpeedDialButton;
