import { Edit, ShoppingCartCheckout, AddShoppingCart, DeleteForever, Engineering } from "@mui/icons-material";
import { deleteCollections } from "../../assets/js/Commons";
import ModalFullScreen from "../containers/ModalFullScreen";
import { db } from "../../assets/context/firebase-config";
import { useThis } from "../../assets/context/Context";
import { deleteDoc, doc } from "firebase/firestore";
import getConstant from "../../assets/js/Constant";
import endpoints from "../../assets/js/Endpoints";
import { Divider, Menu } from "@mui/material";
import Subscriptions from "./Subscriptions";
import { useEffect, useState } from "react";
import DeleteBranch from "./DeleteBranch";
import Modal from "../containers/Modal";
import Message from "./Message";
import Item from "./Item";

/**
 * @name BranchCardMenu
 * @description Método encargado de devolver un componente BranchCardMenu (Opciones: Suscripciones, usuarios, editar y eliminar sucursales)
 * @param {Object} { anchorEl, setAnchorEl, consecutive }
 * @returns View
 * @version 1.0
 */
const BranchCardMenu = ({ anchorEl, setAnchorEl, consecutive }) => {
  const css = styles();
  const constant = getConstant();
  const open = Boolean(anchorEl);
  const { lang, snapshotBranches, user } = useThis();

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loading, setLoading] = useState(false);
  const [iconIndex, setIconIndex] = useState(0);
  const [data, setData] = useState(constant.branchCode);
  const [openModalToDelete, setOpenModalToDelete] = useState(false);
  const [openModalSubscriptions, setOpenModalSubscriptions] = useState(false);

  /**
   * @name handleClose
   * @description Método que ejecuta el evento para cerrar el menú
   * @version 1.0
   */
  const handleClose = () => setAnchorEl(null);

  /**
   * @name showModalToDeleteBranch
   * @description Método encargado de mostrar el modal para eliminar la sucursal
   * @version 1.0
   */
  const showModalToDeleteBranch = () => setOpenModalToDelete(true);

  /**
   * @name showModalSubscriptions
   * @description Método encargado de mostrar el modal de suscripciones
   * @version 1.0
   */
  const showModalSubscriptions = () => setOpenModalSubscriptions(true);

  /**
   * @name deleteBranch
   * @description Método encargado de eliminar la sucursal
   * @version 1.0
   */
  const deleteBranch = async () => {
    if (iconIndex === 0) return setSnack([31, constant.error]);
    if (iconIndex === 2) return setSnack([30, constant.error]);
    setLoading(true);
    const branch = snapshotBranches.find((doc) => doc.data().consecutive.toString().padStart(3, "0") === data.cod1 + data.cod2 + data.cod3);
    try {
      // Se manda a eliminar los documentos de la sucursal
      await deleteDoc(doc(db, endpoints.branch(user.uid, branch.id)));
      // Se manda a eliminar las colecciones de la sucursal
      let result = await deleteCollections(endpoints.subscriptions(user.uid, branch.id));
      if (!result) return setSnack([34, constant.error]);
      // Continua si la eliminación fue satisfactoria
      setSnack([33, constant.success]);
      setOpenModalToDelete(false);
    } catch (error) {
      console.log(error);
      setSnack([32, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @description Objeto para la creación del modal Eliminar Sucursal
   * @version 1.0
   */
  const parameters = {
    btnText: lang.delete,
    title: lang.deleteBranch,
    icon: <DeleteForever />,
    content: <DeleteBranch data={data} setData={setData} consecutive={consecutive} iconIndex={iconIndex} />,
  };

  /**
   * @description Objeto para la creación del modal fullScreen de suscripciones
   * @version 1.0
   */
  const subscriptionParameters = {
    title: lang.subscriptions,
    icon: <ShoppingCartCheckout />,
    content: <Subscriptions consecutiveBranch={consecutive} />,
  };

  /**
   * Efecto para validar los códigos ingresados en los 3 inputs
   */
  useEffect(() => {
    const inputCodes = data.cod1 + data.cod2 + data.cod3;
    if (inputCodes === consecutive) return setIconIndex(1);
    if (data.cod1 === "" || data.cod2 === "" || data.cod3 === "") return setIconIndex(0);
    if (data.cod1 !== "" && data.cod2 !== "" && data.cod3 !== "" && inputCodes !== consecutive) return setIconIndex(2);
  }, [data]);

  return (
    <div>
      <Menu
        open={open}
        anchorEl={anchorEl}
        slotProps={css.menu}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={css.anchor}
        transformOrigin={css.transform}
      >
        <Item css={css.user} action={() => {}} icon={<Engineering fontSize="small" />} text={lang.manageUsers} />
        <Item css={css.sub} action={showModalSubscriptions} icon={<AddShoppingCart fontSize="small" />} text={lang.subscriptions} />
        <Divider />
        <Item css={css.edit} action={() => {}} icon={<Edit fontSize="small" />} text={lang.editBranch} />
        <Item css={css.delete} action={showModalToDeleteBranch} icon={<DeleteForever fontSize="small" />} text={lang.deleteBranch} />
      </Menu>
      <Modal
        loading={loading}
        icon={parameters.icon}
        title={parameters.title}
        open={openModalToDelete}
        clickBtnOk={deleteBranch}
        color={constant.deleteColor}
        btnText={parameters.btnText}
        content={parameters.content}
        setOpen={setOpenModalToDelete}
      />
      <ModalFullScreen
        show={openModalSubscriptions}
        icon={subscriptionParameters.icon}
        setShow={setOpenModalSubscriptions}
        title={subscriptionParameters.title}
        content={subscriptionParameters.content}
      />
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    menu: {
      elevation: 0,
      sx: {
        mt: 1.5,
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        "& .MuiAvatar-root": {
          mr: 1,
          ml: -0.5,
          width: 32,
          height: 32,
        },
        "&:before": {
          top: 0,
          zIndex: 0,
          right: 14,
          width: 10,
          height: 10,
          content: '""',
          display: "block",
          position: "absolute",
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
        },
      },
    },
    sub: { color: "#6AA5DC" },
    user: { color: "#24ad62" },
    edit: { color: "#AFA73B" },
    delete: { color: "#A7524C" },
    anchor: { horizontal: "right", vertical: "bottom" },
    transform: { horizontal: "right", vertical: "top" },
  };
};

export default BranchCardMenu;
