import { TextField, ThemeProvider, Tooltip, Zoom, createTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useThis } from "../../assets/context/Context";
import WarningIcon from "@mui/icons-material/Warning";
import PendingIcon from "@mui/icons-material/Pending";
import CancelIcon from "@mui/icons-material/Cancel";
import getConstant from "../../assets/js/Constant";
import Label from "./Label";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name DeleteBranch
 * @description Método encargado de mostrar el modal para eliminar una sucursal
 * @param {Object} { data, setData, consecutive, iconIndex }
 * @returns View
 * @version 1.0
 */
const DeleteBranch = ({ data, setData, consecutive, iconIndex }) => {
  const css = styles();
  const { lang } = useThis();
  const array = consecutive.split("");

  const arrayIcons = [<PendingIcon sx={css.pendingIcon} />, <CheckCircleIcon sx={css.checkIcon} />, <CancelIcon sx={css.cancelIcon} />];

  /**
   * @name onChange
   * @description Obtiene los datos de los componentes
   * @param {Event} e
   * @version 1.0
   */
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  return (
    <div style={css.container}>
      <Label text={lang.deleteBranchMessage + consecutive} margin={"0 0 15px 0"} />
      <div style={css.containerWarning}>
        <WarningIcon sx={css.warningIcon} />
        <Label fontSize={"12px"} text={lang.deleteBranchWarning} margin={"0 0 15px 0"} fontWeight={"500"} />
      </div>
      <div style={css.containerInputs}>
        <p style={css.branchText}>{lang.branch + ":"}</p>
        <ThemeProvider theme={theme}>
          <TextField
            name="cod1"
            size={"small"}
            sx={css.input}
            color="classic"
            value={data.cod1}
            variant="outlined"
            onChange={onChange}
            placeholder={array[0]}
            inputProps={css.props}
          />
          <TextField
            name="cod2"
            size={"small"}
            sx={css.input}
            color="classic"
            value={data.cod2}
            variant="outlined"
            onChange={onChange}
            placeholder={array[1]}
            inputProps={css.props}
          />
          <TextField
            name="cod3"
            size={"small"}
            sx={css.input}
            color="classic"
            value={data.cod3}
            variant="outlined"
            onChange={onChange}
            placeholder={array[2]}
            inputProps={css.props}
          />
        </ThemeProvider>
        <Tooltip TransitionComponent={Zoom} title={iconIndex === 0 && lang.codeBranchMessage.replace("[CODE]", consecutive)}>
          {arrayIcons[iconIndex]}
        </Tooltip>
      </div>
    </div>
  );
};

/**
 * @name theme
 * @description Método encargado de crear el estilo para el input
 * @version 1.0
 */
const theme = createTheme({
  palette: {
    classic: {
      main: constant.deleteColor,
    },
  },
});

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    container: { width: "300px" },
    props: { style: { textAlign: "center" } },
    input: { width: "40px" },
    containerWarning: { display: "flex" },
    branchText: { fontSize: "22px" },
    warningIcon: { color: "#D8C249", marginRight: "10px", fontSize: "30px" },
    pendingIcon: { fontSize: "40px", cursor: "pointer", color: "#333" },
    checkIcon: { fontSize: "40px", cursor: "pointer", color: constant.checkColor },
    cancelIcon: { fontSize: "40px", cursor: "pointer", color: constant.deleteColor },
    containerInputs: { display: "flex", justifyContent: "space-evenly", alignItems: "center" },
  };
};

export default DeleteBranch;
