import { collection, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import InitMessage from "../../views/components/InitMessage";
import BranchCard from "../../views/components/BranchCard";
import { db, storage } from "../context/firebase-config";
import getConstant from "./Constant";
import endpoints from "./Endpoints";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name userRegistration
 * @description Método encargado de ejecutar las funciones de Firebase Auth para registro y edición de userName
 * @param {*} userName - Recibe el nombre de usuario
 * @param {*} email - Recibe el correo electrónico
 * @param {*} password - Recibe la contraseña
 * @param {*} signup  - Recibe el método que llama la función de Firebase createUserWithEmailAndPassword
 * @param {*} updateUser - Recibe el método que llama la función de Firebase updateProfile
 * @returns Object
 */
const userRegistration = async ({ userName, email, password, signup, updateUser }) => {
  const result = { error: false };
  try {
    const response = await signup(email, password);
    await updateUser(response.user, userName);
  } catch (error) {
    switch (error.code) {
      case "auth/invalid-email":
        result.error = true;
        result.code = error.code;
        result.message = 6;
        break;
      case "auth/weak-password":
        result.error = true;
        result.code = error.code;
        result.message = 7;
        break;
      case "auth/email-already-in-use":
        result.error = true;
        result.code = error.code;
        result.message = 8;
        break;
      default:
        result.error = true;
        result.code = error.code;
        result.message = 9;
        break;
    }
  }
  return result;
};

/**
 * @name addClaims
 * @description Método encargado de agregar reclamos a un usuario
 * @param {Object} user - Usuario de Firebase (token)
 * @param {String} collaborator - Id del colaborador
 * @returns Boolean
 */
const addClaims = async (user, collaborator) => {
  fetch(constant.functionsBasePath + "/addClaims", {
    method: "POST",
    body: JSON.stringify({ collaborator }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  })
    .then((response) => response.json())
    .then(() => true)
    .catch(() => false);
};

/**
 * @name getClaims
 * @description Método que obtiene los reclamos de un usuario
 * @param {Object} user
 */
const getClaims = async (user) => {
  // Accede a los reclamos del usuario
  const userClaims = user.getIdTokenResult();
  userClaims.then((tokenResult) => tokenResult.claims).catch(() => false);
};

/**
 * @name createBranch
 * @description Método encargado de llamar al endpoint para crear una nueva sucursal
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} dataBranch - Datos de la nueva sucursal
 * @returns Boolean
 * @version 1.0
 */
const createBranch = async (user, dataBranch) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify({ ...dataBranch }),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/createBranch", options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 23 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 23 };
  }
};

/**
 * @name getBranches
 * @description Método encargado de consultar las sucursales
 * @param {useState} setView
 * @param {useState} setYearText
 * @param {String} userID
 * @param {Boolean} isMobile
 * @param {useState} setSnapshotBranches
 * @version 1.0
 */
const getBranches = async (setView, setYearText, userID, isMobile, setSnapshotBranches) => {
  try {
    const collectionBranches = collection(db, endpoints.branches(userID));
    const queryBranches = query(collectionBranches, orderBy("creationDate", "asc"));
    // Escucha los cambios en los datos de las sucursales
    onSnapshot(queryBranches, (snapshot) => {
      // Almacena las sucursales en una variable de Context.js
      setSnapshotBranches(snapshot.docs);
      // Almacena los componentes de cada sucursal
      const branches = snapshot.docs.reduce((acc, doc) => {
        acc.push(getComponentCardBranch(doc.id, doc.data()));
        return acc;
      }, []);
      // Muestra las sucursales en el lobby, en el caso de no haber muestra el mensaje animado.
      showBranchesOrInitialMessage(branches, setYearText, setView, isMobile);
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * @name showBranchesOrInitialMessage
 * @description Método encargado de mostrar las sucursales en el lobby, en el caso de no haber muestra el mensaje animado.
 * @param {Array} branches
 * @param {useState} setYearText
 * @param {useState} setView
 * @param {Boolean} isMobile
 * @version 1.0
 */
const showBranchesOrInitialMessage = (branches, setYearText, setView, isMobile) => {
  if (branches.length === 0) {
    setYearText("block");
    setView(<InitMessage />);
  } else {
    setYearText("none");
    setView(<ContainerCardBranch list={branches} isMobile={isMobile} />);
  }
};

/**
 * @name getComponentCardBranch
 * @description Método encargado de obtener el card de la sucursal con los datos cargados
 * @param {String} key
 * @param {Object} data
 * @returns View
 * @version 1.0
 */
const getComponentCardBranch = (key, data) => {
  return (
    <BranchCard
      id={key}
      key={key}
      name={data.name}
      img={data.logo}
      ide={data.identification}
      address={data.otherAddress}
      initialDate={data.creationDate}
      electronicBill={data.electronicBill}
      activityCode={data.activityCode.code}
      expirationDate={data.plan.expirationDate}
      consecutive={data.consecutive.toString().padStart(3, "0")}
    />
  );
};

/**
 * @name ContainerCardBranch
 * @description Método encargado de obtener el contenedor con cada una de las sucursales
 * @param {Object} { list, isMobile }
 * @returns View
 */
const ContainerCardBranch = ({ list, isMobile }) => {
  const css = styles(list.length, isMobile);
  return <div style={css.container}>{list}</div>;
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Number} items
 * @param {Boolean} isMobile
 * @returns {Object}
 * @version 1.0
 */
const styles = (items, isMobile) => {
  return {
    container: {
      width: "100%",
      marginTop: "60px",
      overflowY: "auto",
      position: "relative",
      justifyContent: "center",
      height: "calc(100vh - 60px)",
      gridTemplateColumns: "repeat(auto-fill, 370px)",
      display: items > 2 ? "grid" : isMobile ? "grid" : "flex",
    },
  };
};

/**
 * @name getAllSubscriptions
 * @description Método encargado de obtener todas las suscripciones de una sucursal en específico
 * @param {String} userID
 * @param {String} branchID
 * @returns Object con el response
 */
const getAllSubscriptions = async (userID, branchID) => {
  try {
    const subscriptions = await getDocs(query(collection(db, endpoints.subscriptions(userID, branchID)), orderBy("subscriptionDate", "asc")));
    return { error: false, subscriptions };
  } catch (error) {
    return { error: true, errorCode: 35 };
  }
};

/**
 * @name getConfig
 * @description Método encargado de llamar al endpoint para obtener la configuración
 * @param {Object} user - Usuario de Firebase (token)
 * @returns {Object}
 * @version 1.0
 */
const getConfig = async (user) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
  };
  try {
    // Se realiza la petición de configuraciones al servicio
    let response = await fetch(constant.functionsBasePath + "/getConfig", options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 26 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 26 };
  }
};

/**
 * @name setSubscription
 * @description Método encargado de llamar al endpoint para crear una suscripción
 * @param {Object} user - Usuario de Firebase (token)
 * @param {Object} data - Datos de la suscripción
 * @returns Object
 * @version 1.0
 */
const setSubscription = async (user, data) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.accessToken}`,
    },
    body: JSON.stringify({ ...data }),
  };
  try {
    // Se realiza la petición de escritura
    let response = await fetch(constant.functionsBasePath + "/insertSubscription", options);
    // Se verifica una respuesta válida
    if (!response.ok) return { error: true, errorCode: 37 };
    response = await response.json();
    return response;
  } catch (error) {
    return { error: true, errorCode: 37 };
  }
};

/**
 * @name uploadAnyFile
 * @description Método que almacena un archivo en el Storage de Firebase
 * @param {String} url
 * @param {File} file
 * @returns {Object}
 * @version 1.0
 */
const uploadAnyFile = async (url, file) => {
  try {
    const storageRef = ref(storage, url);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return { error: false, url: downloadURL, storageRef };
  } catch (error) {
    return { error: true, errorCode: 42 };
  }
};

export {
  addClaims, // Método encargado de agregar reclamos a un usuario
  getClaims, // Método que obtiene los reclamos de un usuario
  getConfig, // Método encargado de llamar al endpoint para obtener la configuración
  getBranches, // Método encargado de consultar las sucursales
  createBranch, // Método encargado de llamar al endpoint para crear una nueva sucursal
  uploadAnyFile, // Método que almacena un archivo en el Storage de Firebase
  setSubscription, // Método encargado de llamar al endpoint para crear una suscripción
  userRegistration, // Método encargado de ejecutar las funciones de Firebase Auth para registro y edición de userName
  getAllSubscriptions, // Método encargado de obtener todas las suscripciones de una sucursal en específico
};
