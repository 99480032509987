import { formatNumber, termText } from "../../assets/js/Commons";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import ArrowButton from "./ArrowButton";
import pc from "../../images/pc.svg";
import { useState } from "react";
import Message from "./Message";
import Label from "./Label";

/**
 * @name RenewStep3
 * @description Método que devuelve un componente de tipo RenewStep3
 * @returns View
 * @version 1.0
 */
const RenewStep3 = () => {
  const css = styles();
  const constant = getConstant();
  const { setStep, lang, docTypes, dataRenew, config, dataBranch } = useThis();
  const plan = config.subscription[dataBranch.country.code].plan[dataRenew.docTypes.code][dataRenew.term];

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [currency] = useState(config.subscription[dataBranch.country.code].currency);

  /**
   * @name next
   * @description Método que avanza un paso del Stepper
   * @returns N/A
   */
  const next = () => {
    setStep(3);
  };

  /**
   * @name back
   * @description Método que retrocede en el Stepper
   * @returns N/A
   */
  const back = () => {
    setStep(1);
  };

  return (
    <div style={css.container}>
      <div style={css.fragment}>
        <div style={css.flex}>
          <div style={css.panelLeft}>
            <img src={pc} alt="PC" width={200} height={127} />
          </div>
          <div style={css.panelRight}>
            <div style={css.planText}>
              <img src={docTypes[dataRenew.docTypes.id].icon} alt="img" width={24} height={24} />
              <Label text={docTypes[dataRenew.docTypes.id].name} fontWeight={"500"} fontSize={"16px"} margin={"5px"} />
              <Label text={lang.planSelected} fontSize={"14px"} margin={"5px"} />
            </div>
            <div style={css.planText}>
              <Label text={lang.term + ":"} fontWeight={"500"} fontSize={"16px"} margin={"5px"} />
              <Label text={termText(lang, plan.days)} fontSize={"16px"} margin={"5px"} />
            </div>
            <div style={css.planText}>
              <Label text={lang.price} fontWeight={"500"} fontSize={"16px"} margin={"5px"} />
              <Label text={currency + formatNumber(plan.amount.toFixed(2))} fontSize={"16px"} margin={"5px"} />
            </div>
          </div>
        </div>
        <div style={css.planText}>
          <FeedbackIcon sx={{ color: constant.primaryColor }} />
          <Label text={lang.note} fontWeight={"500"} fontSize={"18px"} margin={"0 0 5px 5px"} />
        </div>
        <Label text={lang.renewSummary} fontSize={"16px"} width={"600px"} />
      </div>
      <div style={css.footer}>
        <ArrowButton index={0} handleClick={back} />
        <ArrowButton index={1} handleClick={next} />
      </div>
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    flex: { display: "flex" },
    panelLeft: { width: "200px" },
    panelRight: { width: "380px", paddingLeft: "20px" },
    planText: { display: "flex", alignItems: "center", marginTop: "10px" },
    footer: { display: "flex", justifyContent: "space-between", marginTop: "10px" },
    fragment: { display: "flex", flexDirection: "column", height: "275px", justifyContent: "center" },
    container: { width: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between" },
  };
};

export default RenewStep3;
